import React from "react";
import Layout from "../components/layout";
import { Helmet } from "react-helmet";
import Builder from "../utilities/Builder";
import { useNav } from "../context/NavContext";
import { graphql } from "gatsby";

const NotFound = ({ data, pageContext, location }) => {
  const { settings, notFoundPage } = data;
  const { Blocks, locale } = notFoundPage;
  const { setCurrentLocation } = useNav();
  return (
    <Layout
      settingsData={settings}
      pageData={notFoundPage}
      className={`${notFoundPage.slug} bg--${
        notFoundPage.MainColor || "White"
      }`}
    >
      <Helmet>
        <title>ESG Rating | Page not found</title>
      </Helmet>
      <Builder Blocks={Blocks} pageLang={notFoundPage.locale} />
    </Layout>
  );
};

export const query = graphql`
  query pageQuery {
    settings: strapiSetting(locale: { eq: "en" }) {
      Main {
        SiteDescription
        SiteShortTitle
        SiteTitle
        Logo {
          alternativeText
          localFile {
            extension
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      Header {
        ButtonLinks {
          LinkIcon {
            alternativeText
            localFile {
              extension
              childrenImageSharp {
                gatsbyImageData
              }
            }
          }
          LinkText
          LinkUrl {
            slug
            locale
            internal {
              type
            }
          }
        }
        Column1Sublinks {
          LinkText
          page {
            slug
            locale
            internal {
              type
            }
          }
        }
        Column2Sublinks {
          LinkText
          page {
            slug
            locale
            internal {
              type
            }
          }
        }
      }
      Footer {
        Address {
          address {
            data {
              childMarkdownRemark {
                html
              }
            }
          }
        }
        FooterMenu {
          Label
          Slug {
            internal {
              type
            }
            locale
            slug
          }
        }
        Newsletter {
          Title
          Subtitle
        }
        Mobile {
          Number
        }
        Email {
          Address
        }
      }
    }

    notFoundPage: strapiPage(slug: { eq: "404" }) {
      Blocks {
        ... on STRAPI__COMPONENT_COMPONENTS_HERO {
          HeroTitle
          Background {
            localFile {
              extension
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          IncludeEventureLogo
          strapi_component
        }
      }
    }
  }
`;

export default NotFound;
